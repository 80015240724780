var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "inFormation df",
    },
    [
      _c(
        "div",
        { staticClass: "main-right", staticStyle: { position: "relative" } },
        [
          _c(
            "el-tabs",
            { attrs: { type: "border-card" } },
            [
              _c("el-tab-pane", { attrs: { label: "客户成交详情" } }, [
                _c(
                  "div",
                  { staticClass: "fjsb", staticStyle: { width: "100%" } },
                  [
                    _c(
                      "ul",
                      {
                        staticStyle: {
                          width: "100%",
                          "border-width": "1px",
                          display: "inline-block",
                        },
                      },
                      [
                        _c("li", { staticClass: "tabLists" }, [
                          _c(
                            "table",
                            {
                              staticClass: "tabstyles",
                              attrs: { border: "1", rules: "all" },
                            },
                            [
                              _c("tr", [
                                _c("th", [_vm._v("客户名称：")]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(_vm.info.cname))]),
                                _vm._v(" "),
                                _c("th", [_vm._v("客户创建人:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.info.create_realname)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("收款公司:")]),
                                _vm._v(" "),
                                _vm.info.receiving_unit == 0
                                  ? _c("td")
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.receiving_unit == 1
                                  ? _c("td", [_vm._v("chl")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.receiving_unit == 2
                                  ? _c("td", [_vm._v("beijing-yiizo")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.receiving_unit == 3
                                  ? _c("td", [_vm._v("chongqing-yiizo")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.receiving_unit == 4
                                  ? _c("td", [_vm._v("shenzhen-yiizo")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.receiving_unit == 5
                                  ? _c("td", [_vm._v("media-yiizo")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.receiving_unit == 6
                                  ? _c("td", [
                                      _vm._v("北京丹顶鹤留学咨询服务有限公司"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.receiving_unit == 7
                                  ? _c("td", [
                                      _vm._v("四川丹顶鹤教育咨询有限公司"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("th", [_vm._v("数据所属部门:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.info.data_own_structure_whole_name
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("成交顾问：")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.info.follow_realname)),
                                ]),
                                _vm._v(" "),
                                _c("th", [_vm._v("创建人所属部门:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.info.create_own_structure_whole_name
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("客户创建时间：")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.info.customer_create_time)),
                                ]),
                                _vm._v(" "),
                                _c("th", [_vm._v("客户种类:")]),
                                _vm._v(" "),
                                _vm.info.leibie == 1
                                  ? _c("td", [_vm._v("广告")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.leibie == 2
                                  ? _c("td", [_vm._v("终端")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.leibie == 3
                                  ? _c("td", [_vm._v("渠道")])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("学员来源:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.info.source_name)),
                                ]),
                                _vm._v(" "),
                                _c("th", [_vm._v("汇款人名称:")]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(_vm.info.remitter))]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("缴费日期:")]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(_vm.info.pay_time))]),
                                _vm._v(" "),
                                _c("th", [_vm._v("缴费用途:")]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  _vm._l(
                                    _vm.info.pay_amount_detail,
                                    function (item, index) {
                                      return _c("div", { key: index }, [
                                        item.type == 1
                                          ? _c("div", [
                                              _c("span", [_vm._v("报名费")]),
                                              _c("span", [
                                                _vm._v(
                                                  ":" + _vm._s(item.amount)
                                                ),
                                              ]),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.type == 2
                                          ? _c("div", [
                                              _c("span", [_vm._v("定金")]),
                                              _c("span", [
                                                _vm._v(
                                                  ":" + _vm._s(item.amount)
                                                ),
                                              ]),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.type == 3
                                          ? _c("div", [
                                              _c("span", [_vm._v("学费")]),
                                              _c("span", [
                                                _vm._v(
                                                  ":" + _vm._s(item.amount)
                                                ),
                                              ]),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.type == 4
                                          ? _c("div", [
                                              _c("span", [_vm._v("资料费")]),
                                              _c("span", [
                                                _vm._v(
                                                  ":" + _vm._s(item.amount)
                                                ),
                                              ]),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.type == 5
                                          ? _c("div", [
                                              _c("span", [
                                                _vm._v("语言培训费"),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  ":" + _vm._s(item.amount)
                                                ),
                                              ]),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.type == 6
                                          ? _c("div", [
                                              _c("span", [
                                                _vm._v("普通留学申请费"),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  ":" + _vm._s(item.amount)
                                                ),
                                              ]),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tr",
                                [
                                  _c("th", [_vm._v("支付方式:")]),
                                  _vm._v(" "),
                                  _vm.info.pay_type == 0
                                    ? _c("td", [_vm._v("无")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.info.pay_type == 1
                                    ? _c("td", [_vm._v("微信")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.info.pay_type == 2
                                    ? _c("td", [_vm._v("支付宝")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.info.pay_type == 3
                                    ? _c("td", [_vm._v("刷卡")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.info.pay_type == 4
                                    ? _c("td", [_vm._v("现金")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.info.pay_type == 5
                                    ? _c("td", [_vm._v("银行卡转账")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.info.order_type != 1
                                    ? [
                                        _c("th", [_vm._v("收款金额（元）:")]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.info.money)),
                                        ]),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("所选院校:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.info.school_name)),
                                ]),
                                _vm._v(" "),
                                _c("th", [_vm._v("所选专业:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(_vm.info.major_name) +
                                      "\n                                    "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("所选班级:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(_vm.info.class_name) +
                                      "\n                                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", [_vm._v("支付凭证:")]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "poi blueFont" },
                                  _vm._l(_vm.imgPay, function (item, index) {
                                    return _c("el-image", {
                                      key: index,
                                      staticStyle: {
                                        width: "170px",
                                        height: "120px",
                                        "margin-right": "20px",
                                      },
                                      attrs: {
                                        fit: "contain",
                                        src: item.url,
                                        "preview-src-list": _vm.srcList,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tr",
                                [
                                  _c("th", [_vm._v("备注:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.note))]),
                                  _vm._v(" "),
                                  _vm.info.audit_reason
                                    ? [
                                        _c("th", [_vm._v("驳回原因:")]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "3" } }, [
                                          _vm._v(_vm._s(_vm.info.audit_reason)),
                                        ]),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.types == 2
                  ? _c(
                      "div",
                      { staticClass: "btn-group" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "medium",
                              loading: _vm.adopt,
                            },
                            on: { click: _vm.audit },
                          },
                          [_vm._v("通过")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "medium" },
                            on: { click: _vm.rejects },
                          },
                          [_vm._v("驳回")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核驳回", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "desc" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.ruleForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "desc", $$v)
                      },
                      expression: "ruleForm.desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.reject },
                  on: {
                    click: function ($event) {
                      return _vm.auditStateFun(-1)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogShow
        ? _c("div", { staticClass: "drag" }, [
            _c(
              "div",
              {
                directives: [{ name: "drag", rawName: "v-drag" }],
                staticClass: "drag_box",
              },
              [
                _c("div", { staticClass: "title" }, [
                  _c("span", [_vm._v("来电提醒")]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "iconfont icon-cha",
                    on: { click: _vm.DialogClick },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "Main" }, [
                  _vm._v("\n                您有\n                "),
                  _c("span", [_vm._v(_vm._s(_vm.calls))]),
                  _vm._v(" "),
                  _c("span", [_vm._v("（" + _vm._s(_vm.MobileAddress) + "）")]),
                  _vm._v("的来电请您到客户列表电话条处接听\n            "),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }